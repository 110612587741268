.header {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 1362px;
  hr {
    width: 100%;
    border-color: #e8d8bb;
    margin-left: 279px;
  }
}

.header-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 212px;
  padding: 3px 0px;
  position: relative;
  width: 100%;
  margin: 10px 279px 0 279px;
}

.menu {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.devider {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.devider-2 {
  align-self: stretch;
  height: 1px;
  margin-top: -1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.side-icon {
  display: none;
}

@media (max-width: 1600px) {
  .header {
    width: 94%;
    hr {
      margin-left: 40px;
    }
  }

  .header-2 {
    margin: 10px 40px 0 40px;
  }
}
@media (max-width: 1300px) {
  .header {
    // width: 1230px;
    hr {
      margin-left: 30px;
    }
  }
  .header-2 {
    margin: 10px 30px 0 30px;
  }
}
@media (max-width: 1200px) {
  .header-2 {
    gap: 170px;
  }
}

@media (max-width: 1000px) {
  .header-2 {
    gap: 136px;
  }
}

@media (max-width: 900px) {
  .menu {
    display: none;
  }
  .side-icon {
    display: block;
  }
  .header-2 {
    gap: 70%;
  }
}
@media (max-width: 700px) {
  .header-2 {
    gap: 55%;
  }
}
@media (max-width: 500px) {
  .header-2 {
    gap: 50%;
  }
  .header-2 {
    margin: 10px 20px 0 20px;
  }
  .header {
    hr {
      margin-left: 20px;
      width: 96%;
    }
  }
}
@media (max-width: 400px) {
  .header-2 {
    gap: 38%;
  }
}
