.toggle-switch {
  width: 136px;
  height: 40px;
  background: white; /* Set background to white */
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
  box-shadow: 0 0 0 4px transparent; /* Space for the gradient border */
  background-clip: padding-box; /* Ensure background doesn't overlap border */
}

.toggle-switch::before{
  content: "";
  position: absolute;
  top: -2px; /* Match the border width */
  left: -2px; /* Match the border width */
  right: -2px; /* Match the border width */
  bottom: -2px; /* Match the border width */
  border-radius: 50px; /* Match the toggle switch's border radius */
  background: linear-gradient(
    -60deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  ) !important; /* Gradient border */
  z-index: -1; /* Ensure it stays behind the toggle switch */
}

.toggle-knob {
  width: 30px;
  height: 30px;
  background: linear-gradient(
    90deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  );
  border-radius: 50%;
  position: absolute;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-knob.light {
  transform: translateX(40px); /* Shift the knob to the right */
}

.toggle-knob.dark {
  transform: translateX(0); /* Keep the knob on the left */
}

.icon {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon svg {
  width: 20px;
  height: 20px;
  transition: fill 0.3s ease; /* Smooth color transition */
}

.toggle-knob.light .icon.sun {
  fill: #a39161; /* Darker color for the icon in the knob when light */
}

.toggle-knob.light .icon.moon {
  fill: #f8e7a2; /* Lighter color for the icon outside the knob */
}

.toggle-knob.dark .icon.sun {
  fill: #f8e7a2; /* Lighter color for the icon in the knob when dark */
}

.toggle-knob.dark .icon.moon {
  fill: #a39161; /* Darker color for the icon outside the knob */
}

.gradient-border6 {
  border-radius: 16px;
  padding: 5px;
  background: linear-gradient(
    -60deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  ) !important;
  svg {
    background: transparent !important;
  }
}

@media (max-width: 1300px) {
  .toggle-switch {
    width: 150px;
  }
}
@media (max-width: 900px) {
  .toggle-switch {
    width: 80px;
  }
}
