.mini-chart-container {
  canvas {
    width: 280px;
  }

  @media (max-width: 540px) {
    canvas {
      width: 180px !important;
    }
  }
}

.scroll-w-hidden::-webkit-scrollbar {
  height: 4px;
  border-bottom-right-radius: 16px;
}

.scroll-w-hidden::-webkit-scrollbar-track {
  background: transparent;
}

.scroll-w-hidden::-webkit-scrollbar-thumb {
  background: linear-gradient(
    90deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #f4d980 32%,
    #e3ba6a 48%,
    #f4d067 64%,
    #c3a364 80%,
    #956e0f 100%
  );
  border-radius: 50px;
}

.chart-items {
  cursor: pointer;
  display: flex;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  border-radius: 0.625rem;
  border: 1px solid var(--gradient-golden, #a27b1f);
  background: #fff;

  /* button-default */
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);

  color: var(--on-god, #5a3c1a);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.75rem */
}

.chart-name {
  width: fit-content;
  display: flex;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  border-radius: 1rem;
  border: 1px solid var(--gradient-golden, #a27b1f);
  background: var(
    --gradient-gold-slanted,
    linear-gradient(
      126deg,
      #a27b1f 1.44%,
      #d2aa46 17.18%,
      #fae68c 32.92%,
      #e3ba6a 48.66%,
      #fdda75 64.4%,
      #c3a364 80.14%,
      #956e0f 99.82%
    )
  );

  /* button-default */
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);

  color: var(--on-god, #5a3c1a) !important;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.75rem */
}

.time-range-selector {
  display: flex;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  border-radius: 1rem;
  border: 1px solid var(--gradient-golden, #a27b1f);
  background: var(
    --gradient-gold-slanted,
    linear-gradient(
      126deg,
      #a27b1f 1.44%,
      #d2aa46 17.18%,
      #fae68c 32.92%,
      #e3ba6a 48.66%,
      #fdda75 64.4%,
      #c3a364 80.14%,
      #956e0f 99.82%
    )
  );

  /* button-default */
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);

  select {
    background-color: transparent !important;
    color: var(--on-god, #5a3c1a) !important;
  }

  select:focus {
    outline: none;
  }
  option {
    width: 100%;
  }
}

.chart-container {
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  flex-shrink: 0;

  border-radius: 1rem;
  border: 1px solid var(--gradient-gold-slanted, #a27b1f);
  background: var(--white, #fff);

  /* container */
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2),
    0px 0px 8px 1px rgba(0, 0, 0, 0.15);

  .chart-title {
    text-align: center;
    padding: 0.8rem 1.5rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem 1rem 0rem 0rem;
    border-bottom: 0.5px solid var(--devider1, #e9d2a8);
    background: var(
      --1,
      linear-gradient(
        98deg,
        #a27b1f -11.22%,
        #d2aa46 10.36%,
        #f4d980 31.94%,
        #e3ba6a 53.52%,
        #f4d067 75.1%,
        #c3a364 96.68%,
        #956e0f 123.65%
      )
    );

    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    line-height: normal;
  }

  select {
    display: flex;
    margin: 1.75rem;
    padding: 1rem;
    justify-content: flex-end;
    align-items: center;
    gap: 14.875rem;
    flex-shrink: 0;

    border-radius: 0.25rem;
    border: 0.5px solid #969696;
    background: #fff;
  }

  canvas {
    margin-bottom: 2.5rem;
  }

  .chart-line-wrapper {
    height: 400px;
  }
  @media (max-width: 540px) {
    .chart-line-wrapper {
      height: 240px;
    }
  }
}

@media (max-width: 1000px) {
  .chart-page {
    margin-inline: 2% !important;
  }
}
