.aboutus-container {
  //   background: #fdfbf7;
  width: 100%;
  padding: 5.96rem 0 6.73rem 0;
  display: grid;
  grid-template-columns: 30% 30% 30%;
  justify-content: space-between;

  .aboutus-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.375rem;

    .title {
      color: var(--grey-dark, #424848);
      text-align: center;
      font-size: 1.625rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 2.275rem */
    }

    .white-shadow {
      box-shadow: (0px 0px 44px rgba(244, 228, 198, 0.39)) !important;
    }

    .aboutus-icon {
      fill: #fff;
      border-radius: 50%;
      padding: 3.5rem;
      fill: #fff;
      box-shadow: (0px 0px 44px rgba(0, 0, 0, 0.06));
    }

    button {
      a {
        background-color: transparent !important;
        color: var(--on-god, #5a3c1a) !important;
        text-align: center;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 1.75rem */
      }
      display: flex;
      //   width: 11.25rem;
      padding: 0.8125rem 2.2rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;

      border-radius: 1rem;
      background: var(
        --gradient-gold-slanted,
        linear-gradient(
          126deg,
          #a27b1f 1.44%,
          #d2aa46 17.18%,
          #fae68c 32.92%,
          #e3ba6a 48.66%,
          #fdda75 64.4%,
          #c3a364 80.14%,
          #956e0f 99.82%
        )
      );

      /* button-default */
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
    }
  }

  @media (max-width: 600px) {
    grid-template-columns: 100%;
    gap: 2rem;

    .aboutus-card {
      padding: 0 2rem;
    }
  }
}
