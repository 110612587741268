// Color variables for easier modification
$primary-color: #e8c96a; // Gold color used in headings, borders, and highlights
$text-color: #333; // Dark text color for readability
$bg-color: #f9f9f9; // Light background color for sections
$border-color: #e8c96a; // Border color for images and sections
$link-color: #333; // Color for links

// Font settings
$font-family: "Arial", sans-serif;
$heading-font-size: 2.5rem;
$body-font-size: 1rem;
$mobile-heading-font-size: 2rem;
$mobile-body-font-size: 0.8rem;

// Base styles for responsiveness
@mixin respond-to($breakpoint) {
  @if $breakpoint == tablet {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $breakpoint == mobile {
    @media (max-width: 500px) {
      @content;
    }
  }
}

// Overall container
.about-us-container {
  font-family: $font-family;
  color: $text-color;

  .content-container {
    margin: 120px 279px 0px 279px;
    display: flex;
    flex-direction: column;
  }
}

// Main heading styling
.main-heading {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 75px;

  h1 {
    text-align: center;
    font-size: 4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    background: var(
      --gradient-golden-button,
      linear-gradient(
        98deg,
        #a27b1f -11.22%,
        #d2aa46 10.36%,
        #f4d980 31.94%,
        #e3ba6a 53.52%,
        #f4d067 75.1%,
        #c3a364 96.68%,
        #956e0f 123.65%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @include respond-to(mobile) {
    h1 {
      font-size: $mobile-heading-font-size;
    }
  }
}

// Common section styles for Company Overview, Mission & Values, and Services
section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 16px;

  .text-content {
    border-radius: 1rem;
    h2 {
      position: relative;
      font-size: 2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 2.8rem */
      background: var(
        --gradient-golden-button,
        linear-gradient(
          98deg,
          #a27b1f -11.22%,
          #d2aa46 10.36%,
          #f4d980 31.94%,
          #e3ba6a 53.52%,
          #f4d067 75.1%,
          #c3a364 96.68%,
          #956e0f 123.65%
        )
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p,
    ul {
      font-size: 16px;
      line-height: 1.6;
    }

    @include respond-to(mobile) {
      h2 {
        font-size: 1.3rem;
      }
      p,
      ul {
        font-size: $mobile-body-font-size;
      }
    }
  }

  img {
    width: 400px;
    border-radius: 10px;
    object-fit: cover;
    height: 100%; // This ensures the image takes up 100% of the available height

    @include respond-to(tablet) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  @include respond-to(tablet) {
    flex-direction: column;

    .text-content {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  @include respond-to(mobile) {
    padding: 20px;
  }
}

// Services section specific styles
.our-services {
  .text-content ul {
    list-style-type: none;
    padding-left: 0;

    li {
      margin-bottom: 15px;
      font-size: $body-font-size;
      display: flex;
    }
  }
}

.gradient-border5 {
  margin: 50px 0;
  border-radius: 16px;
  padding: 2px;
  background: linear-gradient(
    90deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  );
}

.gradient-num-border {
  margin-right: 0.75rem;
  border-radius: 6.25rem;
  padding: 2px;
  background: linear-gradient(
    90deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  );
  width: 2rem;
  height: 2rem;
}

.gradient-num {
  color: var(--on-god, #5a3c1a) !important;
  height: 1.8rem;
  display: flex;
  padding: 0.6rem;
  align-items: center;
  gap: 5.3125rem;
  border-radius: 6.25rem;
  background: var(
    --gradient-gold-slanted,
    linear-gradient(
      126deg,
      #a27b1f 1.44%,
      #d2aa46 17.18%,
      #fae68c 32.92%,
      #e3ba6a 48.66%,
      #fdda75 64.4%,
      #c3a364 80.14%,
      #956e0f 99.82%
    )
  );

  /* mode button- selected */
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 0px 8px 1px rgba(0, 0, 0, 0.15);
}

@media (max-width: 1600px) {
  .about-us-container {
    .content-container {
      margin: 120px 2rem 0px 2rem;
    }
  }

  .main-heading {
    img {
      width: 40%;
    }
  }
}

@media (max-width: 1300px) {
  .main-heading {
    img {
      width: 39%;
    }
  }
}

@media (max-width: 500px) {
  .about-us-container {
    section {
      height: fit-content;
      padding: 0;

      p {
        margin: 2rem 1rem 0rem 1rem;
        position: relative;
        top: -3rem;
      }
      img {
        margin: 0;
        height: 12.5rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    .mission-values {
      flex-direction: column-reverse;
    }
    .main-heading {
      img {
        width: 30%;
      }
    }
    .content-container {
      margin: 4rem 2rem 0px 2rem;
    }
  }
  .our-services {
    li {
      display: block !important;
    }
  }
  .service-con {
    * {
      font-size: 0.8rem !important;
    }
    margin: 1rem 1rem 0 1rem !important;
    position: relative;
    top: -2rem;
  }
}
