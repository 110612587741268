.darkmode * {
  background-color: #1f1e1c !important;
  color: #fff !important;
}
.darkmode {
  background-color: #1f1e1c !important;
  color: #fff !important;

  .toggle-switch {
    border: 2px solid #e3ba6a;
  }
}
