.side-menu {
  display: flex;
  padding-top: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  li {
    display: flex;
    height: 3.5rem;
    padding: 0rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    padding: 1rem;

    color: var(--grey-dark, #424848);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 171.429% */
    letter-spacing: 0.03125rem;
  }
}

.sidebar-con {
  width: 20rem;
  max-width: 25rem;
  border-radius: 1rem 0rem 0rem 1rem;
}

.sidebar-header {
  border-bottom: 1px solid var(--gradient-golden-button, #a27b1f);
  padding: 0.75rem 0.75rem 1rem 1.5rem;
}
