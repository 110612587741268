.footer {
  align-items: flex-start;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  width: 1362px;
  hr {
    width: 100%;
    border-color: #e8d8bb;
    margin-left: 279px;
    margin-top: 1.5rem;
  }
}

.subtitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 279px 1.5rem 279px;
}

.social {
  display: flex;
  img {
    width: 3.5rem;
  }
}

.devider {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.devider-2 {
  align-self: stretch;
  height: 1px;
  margin-top: -1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

@media (max-width: 1600px) {
  .footer {
    width: 94%;

    hr {
      margin-left: 40px;
    }
  }

  .subtitle {
    margin: 1.5rem 40px 1.5rem 40px;
  }
}

@media (max-width: 1300px) {
  .footer {
    // width: 1230px;
    hr {
      margin-left: 30px;
    }
  }

  .subtitle {
    margin: 1.5rem 30px 1.5rem 30px;
  }
}

@media (max-width: 500px) {
  .subtitle {
    flex-direction: column-reverse;
    * {
      font-size: 0.9rem;
    }
  }
  .footer hr {
    width: 94%;
  }
  .subtitle {
    width: 94%;
  }
}
