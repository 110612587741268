.exchange-rate-page {
  margin: 1.5rem 17rem 0rem 17rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  font-family: Arial, sans-serif;

  .main-table {
    .item {
      img {
        cursor: pointer;
      }
    }
  }

  .with {
    display: flex;
    flex-direction: column;
  }
  .exchange-rate-container {
    margin-bottom: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
  }
  .exchange-section {
    // width: 669px;
    height: 580px;
    background-color: #e8d8bb;
    border-radius: 16px;
    border: 1px solid #e6e2d3;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .section-header {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      background: linear-gradient(
        -60deg,
        #a27b1f 0%,
        #d2aa46 16%,
        #f4d980 32%,
        #e3ba6a 48%,
        #f4d067 64%,
        #c3a364 80%,
        #956e0f 100%
      );
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #795548;
      padding-bottom: 12px;
      padding-top: 16px;
    }

    .exchange-table::-webkit-scrollbar {
      width: 4px;
      border-bottom-right-radius: 16px;
    }

    .exchange-table::-webkit-scrollbar-track {
      background: #fff;
      border-bottom-right-radius: 16px;
    }

    .exchange-table::-webkit-scrollbar-thumb {
      background: linear-gradient(
        90deg,
        #a27b1f 0%,
        #d2aa46 16%,
        #f4d980 32%,
        #e3ba6a 48%,
        #f4d067 64%,
        #c3a364 80%,
        #956e0f 100%
      );
      border-bottom-right-radius: 16px;
      border-radius: 50px;
      // border: 3px solid orange;
    }

    .exchange-table {
      padding: 8px 8px 8px 12px;
      height: 87%;
      background-color: #fff;
      border-bottom-left-radius: 16px;
      overflow-y: scroll;

      .table-header {
        display: flex;
        justify-content: space-between;
        background-color: #f0ead6;
        padding: 10px;
        border-radius: 16px;
        color: #5a3c1a;
      }

      .table-row {
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 73px;
        padding: 0 12px;
        margin: 1px 1px;
        background-color: #fbfaf8;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        color: #4e342e;

        .difference {
          display: flex;
          align-items: center;

          &.up {
            color: green;
          }

          &.down {
            color: red;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .exchange-section {
      width: 100%;
    }
  }
}

.custom-dropdown {
  position: relative;
  display: inline-block;
  font-family: "Arial", sans-serif;

  .gradient-border {
    width: 223px;
    border-radius: 16px;
    padding: 2px;
    background: linear-gradient(
      90deg,
      #a27b1f 0%,
      #d2aa46 16%,
      #fae68c 32%,
      #e3ba6a 48%,
      #fdda75 64%,
      #c3a364 80%,
      #956e0f 100%
    );
  }

  .gradient-border:focus {
    box-shadow: none;
  }
  .gradient-border:active {
    box-shadow: none;
  }
  .gradient-border:hover {
    box-shadow: 0rem 0rem 0.25rem 0.0625rem rgba(0, 0, 0, 0.4);
  }

  .dropdown-header {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    background: no-repeat center/cover;
    background: linear-gradient(
      -60deg,
      #a27b1f 0%,
      #d2aa46 16%,
      #fae68c 32%,
      #e3ba6a 48%,
      #fdda75 64%,
      #c3a364 80%,
      #956e0f 100%
    );
    padding: 10px 16px;
    border-radius: 16px;
    cursor: pointer;
    position: relative;

    span {
      color: #333;
    }

    .dropdown-bg {
      width: 18px;
      height: 100%;
    }

    .dropdown-arrow {
      border: solid #333;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      margin-left: 10px;
      transition: transform 0.3s ease;

      &.down {
        transform: rotate(45deg);
      }

      &.up {
        transform: rotate(-135deg);
      }
    }
  }

  .dark-drop {
    background-color: #323130 !important;
    .dropdown-option:hover {
      background-color: #1f1e1c !important;
    }
  }

  .dropdown-options {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 10;
    width: 475px;

    .dropdown-option {
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 16px;
      color: #333;
      background-color: transparent;
    }

    .dropdown-option:hover {
      background-color: #fbfaf8;
    }
  }
}

.exchange-rate-page .prices {
  align-items: center;
  background-color: var(--white);
  border-radius: 1rem;
  box-shadow: var(--container);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0rem 0.5rem 2rem;
  // width: 41.5rem;
  height: 28rem;
}

.table-title {
  color: var(--white, #fff);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.75rem */
}
#dark-title {
  color: #1f1e1c !important;
  * {
    color: #1f1e1c !important;
  }
}

.exchange-rate-page .gradient-border3 {
  // width: 100%;
  height: 28.25rem;
  border-radius: 1rem;
  padding: 0.125rem;
  background: linear-gradient(
    -60deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  );
}

.exchange-rate-page .list-title {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  width: 100%;
}

.exchange-rate-page .title-4 {
  align-items: center;
  background: linear-gradient(
    -70deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #f4d980 32%,
    #e3ba6a 48%,
    #f4d067 64%,
    #c3a364 80%,
    #956e0f 100%
  );
  border-bottom-style: solid;
  border-bottom-width: 0.03125rem;
  border-color: var(--devider-1);
  border-radius: 1rem 1rem 0rem 0rem;
  display: flex;
  flex: 0 0 auto;
  gap: 2.53rem;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  padding: 1rem 1.5rem 0.75rem 1.5rem;
  position: relative;
  width: 102.5%;
}

.exchange-rate-page .g {
  align-items: center;
  display: flex;
  gap: 0.25rem;
  position: relative;
  width: 8.75rem;
}

.exchange-rate-page .g-min {
  display: none;
  background-color: #5A3C1A;
  align-items: center;
  gap: 0.25rem;
  position: relative;
  padding: 0.375rem 1rem;
  border-radius: 1rem;
  width: 8rem;
  margin-bottom: 0.2rem;
}

.exchange-rate-page .title-5 {
  color: var(--white, #fff);
  font-family: "Frank Ruhl Libre";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.575rem */
  margin-top: -0.0625rem;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.exchange-rate-page .g-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2rem;
  position: relative;
}

.exchange-rate-page .title-6 {
  color: var(--on-god);
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.575rem;
  margin-top: -0.0625rem;
  position: relative;
  text-align: center;
  width: 5.625rem;
}

.exchange-rate-page .icon-button {
  align-items: center;
  border-radius: 6.25rem;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 0.625rem;
  padding: 0.5rem;
  position: relative;
}

.exchange-rate-page .list-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 21rem;
}

.exchange-rate-page .list-2::-webkit-scrollbar {
  width: 4px;
  height: 1rem;
  border-bottom-right-radius: 16px;
}

.exchange-rate-page .list-2::-webkit-scrollbar-track {
  background: #fff;
  border-bottom-right-radius: 16px;
}

.exchange-rate-page .black-scroll::-webkit-scrollbar-track {
  background: #1f1e1c !important;
}

.exchange-rate-page .list-2::-webkit-scrollbar-thumb {
  background: linear-gradient(
    90deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #f4d980 32%,
    #e3ba6a 48%,
    #f4d067 64%,
    #c3a364 80%,
    #956e0f 100%
  );
  border-bottom-right-radius: 16px;
  border-radius: 50px;
  // border: 3px solid orange;
}

.exchange-rate-page .item {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.03125rem;
  border-color: var(--devider-2);
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0.75rem;
  padding-right: 0rem;
  position: relative;
  margin-right: 0.5rem;
  width: 98.5%;
}

.exchange-rate-page .g-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  position: relative;
  width: 8.75rem;
}

.exchange-rate-page .title-7 {
  color: var(--black);
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.575rem;
  margin-top: -0.0625rem;
  position: relative;
  width: fit-content;
}

.exchange-rate-page .title-8 {
  color: var(--grey);
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.225rem;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
.exchange-rate-page .text {
  color: var(--black);
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.575rem;
  margin-top: -0.0625rem;
  position: relative;
  text-align: center;
  width: 5.625rem;
}

.exchange-rate-page .text-icon {
  align-items: center;
  display: flex;
  gap: 0.25rem;
  position: relative;
  // width: 5.625rem;
}

.exchange-rate-page .title-9 {
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.575rem;
  margin-right: -0.125rem;
  margin-top: -0.0625rem;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.exchange-rate-page .bar-chart-bars-wrapper {
  align-items: center;
  background-color: var(--white);
  border: 0.0625rem solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgb(162, 123, 31),
      rgb(210, 170, 70) 16%,
      rgb(249.69, 230.47, 140.45) 32%,
      rgb(227, 186, 106) 48%,
      rgb(253, 218, 117) 64%,
      rgb(195, 163, 100) 80%,
      rgb(149, 110, 15) 100%
    )
    1;
  border-radius: 6.25rem;
  box-shadow: var(--button-default);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 0.625rem;
  padding: 0.5rem;
  position: relative;
}

@media (max-width: 1600px) {
  .exchange-rate-page {
    // margin: 24px 1.5rem 0rem 1.5rem;
    margin: 24px 5% 0rem 5%;
  }
}
@media (max-width: 1500px) {
  .exchange-rate-page {
    margin: 24px 1.5rem 0rem 1.5rem;
    // margin: 24px 5% 0rem 5%;
  }
}
@media (max-width: 1300px) {
  .exchange-rate-page {
    margin: 24px 1rem 0 1rem;
  }
}
@media (max-width: 1200px) {
  .exchange-rate-page {
    .g-2 {
      gap: 12rem;
    }
    .main-table {
      width: 100%;
    }
  }
}
@media (max-width: 1000px) {
  .exchange-rate-page {
    .g-2 {
      gap: 6rem;
    }
  }
}

@media (max-width: 500px) {
  .exchange-rate-page {
    * {
      font-size: 1rem !important;
    }
    .gradient-border3 {
      width: 100%;
    }
    .prices {
      width: auto;
    }
    .title-4 {
      width: 106%;
    }
    .g-2 {
      gap: 0rem;
    }
  }
}

@media (max-width: 500px) {
  .exchange-rate-page {
    * {
      font-size: 0.8rem !important;
    }
    .exchange-rate-container {
      flex-direction: column;
    }
    .gradient-border {
      width: 12rem;
      margin-left: 4%;
    }
    .dropdown-options {
      width: 88%;
      margin-left: 4%;
    }
    .gradient-border3 {
      width: 100%;
    }
    .title-4 {
      width: 103%;
    }
    .g-2 {
      gap: 0rem;
    }
    .g-min {
      display: flex;
    }
    .text {
      width: 7rem;
    }
    .text-icon {
      width: 4rem;
    }
    .title-6 {
      width: 6rem;
      margin-inline: 0.5rem;
    }
    .g-3 {
      width: fit-content;
    }
    .g {
      display: none;
    }
    // .g {
    //   flex-direction: column;
    //   width: auto;
    // }
    .prices {
      padding: 0rem 0.2rem 1rem 0.2rem;
      height: 20.7rem;
    }
    .item {
      padding: 0.5rem 0rem;
      width: 100%;
    }

    .gradient-border3 {
      height: 21rem;
    }

    .list-2 {
      height: 15rem;
      .text-icon {
        img {
          width: 0.8rem;
        }
      }
    }

    .main-table {
      width: 100%;
    }

    .title-7-sp {
      display: flex;
      flex-direction: column;
    }
  }
}
@media (max-width: 400px) {
  .exchange-rate-page {
    .gradient-border3 {
      width: 100%;
    }
    .text {
      width: 5rem;
    }
    .title-6 {
      width: 5rem;
      margin-inline: 0;
    }
    .title-4 {
      margin: 0;
      padding: 1rem 0.5rem;
    }
  }
}
