.clock {
  width: 120px;
  text-align: center;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border-radius: 1000px;
  // margin-left: -15px;
  // margin-right: 15px;
  margin-bottom: 5px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .digital-clock {
    color: #858888;
    border-radius: 0.5em;
    margin-left: -10px;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  .gradient-border7 {
    border-radius: 50%;
    padding: 2px;
    background: linear-gradient(
      -60deg,
      #a27b1f 0%,
      #d2aa46 16%,
      #fae68c 32%,
      #e3ba6a 48%,
      #fdda75 64%,
      #c3a364 80%,
      #956e0f 100%
    );
    width: 3.5rem;
    height: 3.5rem;
  }
  .analog-clock {
    width: 3.25rem;
    height: 3.25rem;
    border-radius: 50%;
    position: relative;
    background: #252a2a;

    .dial {
      position: absolute;
      left: 50%;
      width: 50px;
      height: 50px;
      border-radius: 4px;
      transform-origin: bottom left;
      background-image: linear-gradient(
        90deg,
        #a27b1f 0%,
        #d2aa46 16%,
        #fae68c 32%,
        #e3ba6a 48%,
        #fdda75 64%,
        #c3a364 80%,
        #956e0f 100%
      );
      background-clip: content-box; /* To clip the gradient inside the content */
    }

    .dial.seconds,
    .dial.minutes,
    .dial.hours {
      background-image: linear-gradient(
        90deg,
        #a27b1f 0%,
        #d2aa46 16%,
        #fae68c 32%,
        #e3ba6a 48%,
        #fdda75 64%,
        #c3a364 80%,
        #956e0f 100%
      );
      border-left: none; /* Remove the border to use the background instead */
      mask-composite: exclude; /* Exclude other areas */
      -webkit-mask-composite: exclude; /* For WebKit browsers */
    }

    .dial.seconds {
      width: 4px;
      height: 32px;
      top: 4px;
    }
    .white-border1 {
      background: #252a2a;
      padding: 2px;
      position: relative;
      top: 5px;
      left: 56%;
      width: 2px;
      height: 17.5px;
    }

    .white-border2 {
      background: #252a2a;
      padding: 2px;
      position: relative;
      bottom: 10px;
      width: 2px;
      height: 13.5px;
      left: 51%;
    }

    .dial.minutes {
      position: relative;
      width: 2px;
      height: 17.5px;
      left: -1px;
      bottom: 5px;
    }

    .dial.hours {
      position: relative;
      left: -1px;
      bottom: 5px;
      width: 2px;
      height: 13.5px;
      background-clip: padding-box;
    }
  }
}

.clock h5 {
  color: #252b2b;
  margin-top: -2px;
  margin-bottom: 5px;
  margin-left: -10px;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.gradient-border8 {
  border-radius: 50%;
  padding: 1px;
  background: linear-gradient(
    90deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  );
  width: 5px;
  height: 5px;
  left: 24px;
  position: relative;
  top: 24px;
  z-index: 99;
  // margin: 2px;
}
.ClockCircle {
  background: linear-gradient(
    -60deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  );
  height: 3px;
  border-radius: 50%;
  z-index: 999;
}

.one {
  font-size: x-small !important;
  top: 5%;
  right: 25%;
}

.two {
  font-size: x-small !important;
  top: 19%;
  right: 10%;
}

.three {
  font-size: x-small !important;
  right: 2px;
  top: 41%;
}

.four {
  font-size: x-small !important;
  right: 7px;
  top: 62%;
}

.five {
  font-size: x-small !important;
  right: 18px;
  top: 80%;
}

.six {
  font-size: x-small !important;
  bottom: -2px;
  left: 46%;
}

.seven {
  font-size: x-small !important;
  left: 18px;
  top: 78%;
}

.eight {
  font-size: x-small !important;
  left: 8px;
  top: 63%;
}

.nine {
  font-size: x-small !important;
  left: 2px;
  top: 42%;
}

.ten {
  font-size: x-small !important;
  top: 23%;
  left: 5%;
}

.eleven {
  font-size: x-small !important;
  top: 7%;
  left: 20%;
}
.twelve {
  font-size: x-small !important;
  top: -1px;
  left: 42%;
}

.clock span {
  position: absolute;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
}

//=================

.react-clock__face {
  background-color: #252a2a;
}

.white-clock {
  .react-clock__face {
    background-color: #fff !important;
    border: none;
  }
}

.react-clock__hand__body {
  background-image: linear-gradient(
    90deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  );
  border-radius: 4px;
  width: 2px !important;
}

.react-clock {
  width: 3.25rem !important;
  height: 3.25rem !important;
  border-radius: 50%;
  display: block;
  
}

.react-clock__hand__minute,
.react-clock__hand__hour {
  background: linear-gradient(
    90deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  );
}

.react-clock__second-hand__body,
.react-clock__mark {
  display: none !important;
}

/* Add custom styles to make the clock text, border, etc., match your design */

@media only screen and (max-device-height: 854px) {
  .analog-clock {
    width: 70px !important;
    height: 50px !important;
  }
  .ClockCircle {
    margin-top: 30px;
    margin-left: 30px;
  }
  .analog-clock {
    .dial {
      &.seconds {
        top: 4px !important;
        height: 30px !important;
      }
      &.minutes {
        height: 23px !important;
        top: 10px !important;
      }
      &.hours {
        right: 15px;
        top: 14px !important;
      }
    }
  }
  .clock {
    .digital-clock {
      padding: 0.1em;
    }
  }
}

