:root {
  --bg: rgba(251, 250, 248, 1);
  --black: rgba(37, 43, 43, 1);
  --button-default: 0rem 0rem 0.25rem 0.0625rem rgba(0, 0, 0, 0.25);
  --container: 0rem 0rem 0.5rem 0.0625rem rgba(0, 0, 0, 0.15),
    0rem 0rem 0.125rem 0rem rgba(0, 0, 0, 0.2);
  --devider-1: rgba(233, 210, 168, 1);
  --devider-2: rgba(232, 216, 187, 1);
  --green: rgba(59, 190, 129, 1);
  --grey: rgba(133, 136, 136, 1);
  --grey-dark: rgba(66, 72, 72, 1);
  --m-3white: rgba(255, 255, 255, 1);
  --m3-body-large-font-family: "Roboto", Helvetica;
  --m3-body-large-font-size: 1rem;
  --m3-body-large-font-style: normal;
  --m3-body-large-font-weight: 400;
  --m3-body-large-letter-spacing: 0.03125rem;
  --m3-body-large-line-height: 1.5rem;
  --mode-button-selected: 0rem 0rem 0.5rem 0.0625rem rgba(0, 0, 0, 0.15),
    0.0625rem 0.0625rem 0.1875rem 0rem rgba(0, 0, 0, 0.2);
  --on-god: rgba(90, 60, 26, 1);
  --red: rgba(246, 53, 53, 1);
  --white: rgba(255, 255, 255, 1);
}

.board-imgs {
  img {
    cursor: pointer;
  }
}

.green-rgba {
  color: var(--green) !important;
}
.red-rgba {
  color: var(--red) !important ;
}

.home {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  .imgs {
    * {
      border-radius: 1rem;
    }
  }
}

.home .overlap-wrapper {
  margin: 0 17.44rem;
  background-color: var(--white);
  width: 100%;
}

.home .overlap {
  margin-top: 5rem;
  background-color: var(--white);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
  align-items: center;
}

.home .converter {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 1rem;
  box-shadow: var(--container);
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 4rem;
}

.gradient-border2 {
  position: relative;
  width: 100%;
  border-radius: 1rem;
  padding: 0.125rem;
  background: linear-gradient(
    -60deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  );
}

.home .text-fields {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 5rem;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.home .unit {
  align-items: flex-end;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 1.5rem;
  position: relative;
}

.home .text-field {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.5rem;
  position: relative;
}

.home .title {
  align-self: stretch;
  color: var(--on-god);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.4rem;
  margin-top: -0.0625rem;
  position: relative;
}

.home .fied {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  border: 0.0625rem solid;
  border-color: var(--devider-1);
  border-radius: 1.25rem;
  display: flex;
  flex: 0 0 auto;
  gap: 0.625rem;
  padding: 1rem 0.75rem 1rem 1rem;
  position: relative;
  width: 100%;

  &:focus-within {
    border: 2px solid;
    border-color: var(--devider-1);
  }
}

.home .content {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
}

.home .text-wrapper {
  color: var(--grey-dark);
  flex: 1;
  font-weight: 400;
  height: 1.5rem;
  letter-spacing: 0;
  line-height: 1.4rem;
  margin-top: -0.0625rem;
  position: relative;
  white-space: nowrap;

  img {
    width: 1.6rem;
  }
}

.content {
  input {
    border: none;
  }
  textarea:focus,
  input:focus {
    outline: none;
  }
}

.home .icon-text {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 0.5rem;
  position: relative;
}

.home .img {
  height: 1.5rem;
  position: relative;
  width: 1.5rem;
}

.home .flags-instance {
  height: 1rem !important;
  left: 0.0625rem !important;
  position: absolute !important;
  top: 0.25rem !important;
  width: 1.375rem !important;
}

.home .button {
  all: unset;
  align-items: center;
  background: linear-gradient(
    -60deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  );
  border-radius: 1rem;
  box-shadow: var(--button-default);
  box-sizing: border-box;
  display: flex;
  gap: 0.625rem;
  justify-content: center;
  overflow: hidden;
  padding: 0.8125rem 1.5rem;
  position: relative;
  width: 11.25rem;
}

.home .div {
  color: var(--on-god);
  font-size: 1.25rem;
  font-weight: 500;
  height: 1.875rem;
  letter-spacing: 0;
  line-height: 1.75rem;
  margin-top: -0.0625rem;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 5rem;
}

.home .result-button {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
}

.home .result {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.25rem;
  position: relative;
}

.home .title-2 {
  display: none;
  align-self: stretch;
  color: var(--grey-dark);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.4rem;
  margin-top: -0.0625rem;
  position: relative;
}

.home .icon-text-2 {
  align-items: center;
  display: inline-flex;
  gap: 0.5rem;
  height: 2.4375rem;
  position: relative;
}

.home .flag-frame {
  img {
    width: 2rem;
  }
}

.home .icon-instance-node {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  position: relative !important;
  width: 100% !important;
}

.home .title-3 {
  color: var(--grey-dark);
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 2.275rem;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .list {
  box-shadow: var(--container);
  height: 12.5rem;
  left: 35.1875rem;
  position: absolute;
  top: 9.625rem;
  width: 29.6875rem;
}

.home .overlap-group {
  height: 12.5rem;
  position: relative;
}

.home .list-density {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 0;
  width: 29.6875rem;
}

.home .list-item {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  min-height: 2.5rem;
  position: relative;
  width: 100%;
}

.home .state-layer-overlay {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 29.6875rem !important;
}

.home .state-layer {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 1rem;
  height: 2.5rem;
  justify-content: center;
  padding: 0rem 1rem;
  position: relative;
  width: 100%;
}

.home .content-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  position: relative;
}

.home .headline {
  align-self: stretch;
  color: var(--grey-dark);
  font-weight: 400;
  letter-spacing: 0.03125rem;
  line-height: 1.5rem;
  position: relative;
}

.home .building-blocks-state-layer-1-enabled {
  background-color: var(--bg) !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 29.6875rem !important;
}

.home .scrollbar {
  height: 12.5rem;
  left: 28.6875rem;
  position: absolute;
  top: 0;
  width: 1rem;
}

.home .scrollbar-2 {
  background: linear-gradient(
    180deg,
    rgb(162, 123, 31) 0%,
    rgb(210, 170, 70) 16%,
    rgb(249.69, 230.47, 140.45) 32%,
    rgb(227, 186, 106) 48%,
    rgb(253, 218, 117) 64%,
    rgb(195, 163, 100) 80%,
    rgb(149, 110, 15) 100%
  );
  border-radius: 6.25rem;
  height: 2.125rem;
  left: 0.5rem;
  position: relative;
  top: 0.5rem;
  width: 0.25rem;
}

.home .board-imgs {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
}

.home .prices {
  align-items: center;
  background-color: var(--white);
  border-radius: 1rem;
  box-shadow: var(--container);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0rem 0.75rem 2rem;
  height: 36rem;
}

.gradient-border3 {
  height: 36.25rem;
  // width: 55.625rem;
  border-radius: 1rem;
  padding: 0.125rem;
  background: linear-gradient(
    -60deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  );
}

.home .list-title {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  width: 100%;
}

.home .title-4 {
  align-items: center;
  background: linear-gradient(
    -70deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #f4d980 32%,
    #e3ba6a 48%,
    #f4d067 64%,
    #c3a364 80%,
    #956e0f 100%
  );
  border-bottom-style: solid;
  border-bottom-width: 0.03125rem;
  border-color: var(--devider-1);
  border-radius: 1rem 1rem 0rem 0rem;
  display: flex;
  flex: 0 0 auto;
  gap: 4.75rem;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  padding: 1.46875rem 0.75rem 1.21875rem 1.5rem;
  position: relative;
  width: 55.625rem;
}

.home .g {
  align-items: center;
  display: flex;
  gap: 0.25rem;
  position: relative;
}

.darkmode {
  .g,
  .g-2,
  .title-13,
  .title-12,
  .title-11,
  .div,
  .dropdown-header img,
  .dropdown-header span,
  .title-4 div {
    background: transparent !important;
    color: #5a3c1a !important;
    * {
      background: transparent !important;
    }
  }
}

.home .title-5 {
  color: var(--white);
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.575rem;
  margin-top: -0.0625rem;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .g-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.5rem;
  position: relative;
}

.home .title-6 {
  color: var(--on-god);
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.575rem;
  margin-top: -0.0625rem;
  position: relative;
  text-align: center;
  width: 5.625rem;
}

.home .icon-button {
  align-items: center;
  border-radius: 6.25rem;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 0.625rem;
  padding: 0.5rem;
  position: relative;
}

.home .list-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.home .item {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.03125rem;
  border-color: var(--devider-2);
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0.75rem;
  position: relative;
  margin-right: 0.8rem;
  // width: 100%;
}

.home .item:hover {
  background-color: #fbfaf8;
}

.home .g-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  position: relative;
  width: 8.75rem;
}

.home .title-7 {
  color: var(--black);
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.575rem;
  margin-top: -0.0625rem;
  position: relative;
  width: fit-content;
}

.home .title-8 {
  color: var(--grey);
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.225rem;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .text {
  color: var(--black);
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.575rem;
  margin-top: -0.0625rem;
  position: relative;
  text-align: center;
  width: 5.625rem;
}

.home .text-icon {
  align-items: center;
  display: flex;
  gap: 0.25rem;
  position: relative;
  width: 5.625rem;
}

.home .title-9 {
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.575rem;
  margin-right: -0.125rem;
  margin-top: -0.0625rem;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .bar-chart-bars-wrapper {
  align-items: center;
  background-color: var(--white);
  border: 0.0625rem solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgb(162, 123, 31),
      rgb(210, 170, 70) 16%,
      rgb(249.69, 230.47, 140.45) 32%,
      rgb(227, 186, 106) 48%,
      rgb(253, 218, 117) 64%,
      rgb(195, 163, 100) 80%,
      rgb(149, 110, 15) 100%
    )
    1;
  border-radius: 6.25rem;
  box-shadow: var(--button-default);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 0.625rem;
  padding: 0.5rem;
  position: relative;
}

.home .title-10 {
  color: var(--red);
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.575rem;
  margin-right: -0.125rem;
  margin-top: -0.0625rem;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .item-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}

.home .buttons {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1rem;
  position: relative;
}

.home .title-wrapper {
  all: unset;
  cursor: pointer;
  align-items: center;
  background: linear-gradient(
    -60deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  );
  border-radius: 1rem;
  box-shadow: var(--button-default);
  box-sizing: border-box;
  display: flex;
  gap: 0.625rem;
  justify-content: center;
  overflow: hidden;
  padding: 0.625rem 1.5rem;
  position: relative;
  width: 11.25rem;
}

.home .title-wrapper:focus {
  box-shadow: none;
}
.home .title-wrapper:active {
  box-shadow: none;
}
.home .title-wrapper:hover {
  box-shadow: 0rem 0rem 0.25rem 0.0625rem rgba(0, 0, 0, 0.4);
}

.home .button:focus {
  box-shadow: none;
}
.home .button:active {
  box-shadow: none;
}
.home .button:hover {
  box-shadow: 0rem 0rem 0.25rem 0.0625rem rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.home .title-11 {
  color: var(--on-god);
  font-size: 1.125rem;
  font-weight: 500;
  height: 1.625rem;
  letter-spacing: 0;
  line-height: 1.575rem;
  margin-top: -0.0625rem;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 5.125rem;
}

.home .title-12 {
  color: var(--on-god);
  font-size: 1.125rem;
  font-weight: 500;
  height: 1.625rem;
  letter-spacing: 0;
  line-height: 1.575rem;
  margin-top: -0.0625rem;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 7.5rem;
}

.home .title-13 {
  color: var(--on-god);
  font-size: 1.125rem;
  font-weight: 500;
  height: 1.625rem;
  letter-spacing: 0;
  line-height: 1.575rem;
  margin-top: -0.0625rem;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 6.1875rem;
}

.home .imgs {
  height: 36.25rem;
  width: 28rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
}

.home .left {
  height: 36.25rem;
  width: 13.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
}

.home .img-gold {
  height: 22.25rem;
  width: 13.75rem;
}

.home .img-currency {
  height: 12.5rem;
  width: 13.75rem;
}

.home .img-crypto {
  height: 36.25rem;
  width: 12.75rem;
}

.home .whatsapp-image {
  height: 3.625rem;
  position: relative;
  width: 6.625rem;
}

.home .menu {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.75rem;
  position: relative;
}

.home .label-indicator {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 0.5rem;
  position: relative;
}

.home .indicator {
  height: 0.625rem;
  margin-left: -0.125rem;
  position: relative;
  width: 0.625rem;
}

.home .title-14 {
  color: var(--black);
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.575rem;
  margin-top: -0.0625rem;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.home .button-2 {
  align-items: center;
  border: 0.0625rem solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgb(162, 123, 31),
      rgb(210, 170, 70) 16%,
      rgb(244.37, 217.29, 128.3) 32%,
      rgb(227, 186, 106) 48%,
      rgb(244.37, 207.95, 102.84) 64%,
      rgb(195, 163, 100) 80%,
      rgb(149, 110, 15) 100%
    )
    1;
  border-radius: 6.25rem;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 0.75rem;
  margin-right: -0.0625rem;
  overflow: hidden;
  padding: 0.375rem;
  position: relative;
}

.home .bedtime-wrapper {
  align-items: center;
  border: 0rem none;
  border-image: linear-gradient(
      to bottom,
      rgb(162, 123, 31),
      rgb(210, 170, 70) 16%,
      rgb(244.37, 217.29, 128.3) 32%,
      rgb(227, 186, 106) 48%,
      rgb(244.37, 207.95, 102.84) 64%,
      rgb(195, 163, 100) 80%,
      rgb(149, 110, 15) 100%
    )
    1;
  border-radius: 6.25rem;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.3125rem;
  padding: 0.5rem;
  position: relative;
}

.home .img-2 {
  height: 1rem;
  position: relative;
  width: 1rem;
}

.home .wb-sunny-wrapper {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgb(162, 123, 31) 0%,
    rgb(210, 170, 70) 16%,
    rgb(249.69, 230.47, 140.45) 32%,
    rgb(227, 186, 106) 48%,
    rgb(253, 218, 117) 64%,
    rgb(195, 163, 100) 80%,
    rgb(149, 110, 15) 100%
  );
  border: 0.0625rem solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgb(162, 123, 31),
      rgb(210, 170, 70) 16%,
      rgb(249.69, 230.47, 140.45) 32%,
      rgb(227, 186, 106) 48%,
      rgb(253, 218, 117) 64%,
      rgb(195, 163, 100) 80%,
      rgb(149, 110, 15) 100%
    )
    1;
  border-radius: 6.25rem;
  box-shadow: var(--mode-button-selected);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.3125rem;
  padding: 0.5rem;
  position: relative;
}

.home .devider {
  align-self: stretch;
  height: 0.0625rem;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.home .devider-2 {
  align-self: stretch;
  height: 0.0625rem;
  margin-top: -0.0625rem;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.home .subtitle {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.home .p {
  color: var(--grey-dark);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.4rem;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .social {
  flex: 0 0 auto;
  margin-bottom: -0.5625rem;
  margin-right: -0.5625rem;
  margin-top: -0.5625rem;
  position: relative;
}

.home .dropdown {
  position: absolute;
  background-color: var(--white);
  box-shadow: var(--container);
  border-radius: 1rem;
  margin-top: 14.25rem;
  margin-left: -1rem;
  z-index: 1;
  width: 100%;
  height: 10rem;
}

.dropdown-inner {
  height: 100%;
  overflow-y: scroll;
  margin-right: 0.5rem;
}

.home .list-2 {
  overflow-x: hidden;
}


.home .dropdown-inner::-webkit-scrollbar,
.home .list-2::-webkit-scrollbar {
  width: 6px;
  height: 1rem;
  border-bottom-right-radius: 16px;
}

.home .dropdown-inner::-webkit-scrollbar-track,
.home .list-2::-webkit-scrollbar-track {
  background: #fff;
  border-bottom-right-radius: 16px;
}

.home .black-scroll::-webkit-scrollbar-track {
  background: #1f1e1c !important;
}

.home .dropdown-inner::-webkit-scrollbar-thumb,
.home .list-2::-webkit-scrollbar-thumb {
  background: linear-gradient(
    90deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #f4d980 32%,
    #e3ba6a 48%,
    #f4d067 64%,
    #c3a364 80%,
    #956e0f 100%
  );
  border-bottom-right-radius: 16px;
  border-radius: 50px;
  // border: 3px solid orange;
}

.home .dropdown-item {
  padding: 0.75rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.converter .dropdown .dropdown-inner .dropdown-item:hover {
  background-color: #fbfaf8;
}

.home .dropdown-item .icon-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.home .dropdown-item .img {
  width: 1.5rem;
  height: 1.5rem;
}

/* Adjust the position of the dropdown relative to the "From" field */
.home .text-field .fied {
  position: relative;
}

.home .rotate-arrow {
  transform: rotate(90deg);
  transition: transform 0.3s ease; /* Smooth rotation transition */
}

.gradient-border10 {
  background: linear-gradient(
    -60deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  );
  width: 100%;
  padding: 2px;
  border-radius: 16px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2),
    0px 0px 8px 1px rgba(0, 0, 0, 0.15);
}

.announcement {
  border-radius: 16px;
  background-color: #fff;
  display: flex;
  padding: 1.5rem 2rem;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;

  color: var(--on-god, #5a3c1a);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.75rem */
}

.img-gold-ad {
  display: none;
}

.main-table {
  width: 48%;
}

@media (max-width: 1400px) {
  .main-table {
    // width: 42%;
  }
}
@media (max-width: 1300px) {
  .main-table {
    width: 46%;
  }
  .home .title-4 {
    width: 104%;
  }
  .home .g-2 {
    gap: 3rem;
  }
  .gradient-border3 {
    // width: 60%;
  }
  .home .overlap-wrapper {
    margin: 0 2rem;
  }
}

@media (max-width: 1200px) {
  .main-table {
    width: 62%;
  }
  .gradient-border10 {
    width: 90%;
    margin: 1rem;
  }
  .home .overlap {
    margin: 1rem 1rem 0 1rem;
  }
  .home .title-4 {
    gap: 1rem;
  }
}

@media (max-width: 1000px) {
  .main-table {
    width: 80%;
  }
  .home .g {
    flex-direction: column;
  }
  .gradient-border2 {
    width: 94%;
  }

  .home .board-imgs {
    flex-direction: column;
  }

  .home .g-2 {
    gap: 5rem;
  }
  .home .title-4 {
    gap: 8rem;
  }
  .gradient-border3 {
    // width: 90%;
  }

  .img-crypto,
  .img-currency,
  .img-gold {
    display: none;
  }
  .home .imgs,
  .home .left {
    height: auto;
    width: 90%;
  }
  .img-gold-1080 {
    display: block;
    width: 49rem;
    height: 22.25rem;
    object-fit: cover;
  }
}

@media (max-width: 800px) {
  .main-table {
    width: 100%;
  }
  .home .title-4 {
    gap: 6rem;
  }
  .home .g-2 {
    gap: 3rem;
  }
  .home .converter {
    padding: 1rem;
  }
  .home .text-field {
    width: 50%;
  }
  .home .text-wrapper {
    width: 98%;
  }
  .home .title-3 {
    font-size: 1.4rem;
  }
}

@media (max-width: 500px) {
  .announcement {
    padding: 0.75rem 1rem;
    flex-direction: column;
    align-items: flex-end;
    gap: 2.5rem;
    font-size: 0.9rem;
  }
  .home .board-imgs,
  .home .converter {
    font-size: 0.8rem;
    * {
      font-size: 0.8rem;
    }
  }
  .home .title-4 {
    gap: 15%;
    padding: 0.5rem 0.75rem 0.5rem 1.5rem;
    width: 107%;
  }
  .home .g-2 {
    gap: 0rem;
    .text-icon {
      img {
        width: 0.8rem;
      }
    }
  }
  .home .board-imgs {
    width: 98%;
  }
  .home .g-3 {
    width: 6rem;
  }
  .home .imgs {
    display: block;
  }
  .home .buttons {
    flex-direction: column;
    width: 100%;
    margin-top: 2rem;
  }
  .home .title-wrapper {
    width: 100%;
  }
  .home .gradient-border3,
  .home .prices {
    height: fit-content;
  }
  .img-gold-1080 {
    display: none;
  }
  .img-gold-ad {
    display: block;
    width: 100%;
  }
  .home .text-fields {
    gap: 3.5rem;
  }
  .home .text-fields,
  .home .unit {
    flex-direction: column;
    width: 100%;
  }
  .home .text-field,
  .home .button {
    width: 100%;
  }
  .home .result-button {
    flex-direction: column;
    align-items: flex-start;
  }
  .home .title-11,
  .home .title-12,
  .home .title-13,
  .home .div {
    font-size: 1rem;
  }
  .home .title-3 {
    font-size: 1.25rem;
  }
  .gradient-border2 {
    width: 86%;
  }
  .home .list-2 {
    .item {
      margin-right: 0;
    }
  }
}

@media (max-width: 500px) {
  .home .overlap {
    margin: 1rem 0;
  }
  .home .overlap-wrapper {
    margin: 0;
  }
  .home .text {
    width: 7rem;
  }
  .home .title-4 {
    width: 106%;
  }
  .header-2 {
    margin: 0px 15px 0 15px;
  }
  .home .prices {
    padding: 0rem 0.5rem 1rem 0.5rem;
  }
  .home .item {
    padding: 0.5rem 0rem;
  }
  .home .text-icon {
    width: 4rem;
  }
  .ml-2 {
    width: 2rem;
    display: none;
  }
  .title-6 {
    width: 4.4rem !important;
  }
  .title-7-sp {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 400px) {
  .home .title-4 {
    gap: 8%;
  }
  .home .text {
    width: 5rem;
  }
}
