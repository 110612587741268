.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4.69rem;
}

.contact-us-container {
  margin-bottom: 6rem;
  h1 {
    text-align: center;
    font-size: 4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    background: var(
      --gradient-golden-button,
      linear-gradient(
        98deg,
        #a27b1f -11.22%,
        #d2aa46 10.36%,
        #f4d980 31.94%,
        #e3ba6a 53.52%,
        #f4d067 75.1%,
        #c3a364 96.68%,
        #956e0f 123.65%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .contact-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem 2rem 2rem;
    align-items: flex-start;
    gap: 0.625rem;
    flex: 1 0 0;
    border-radius: 16px;

    .title {
      display: inline-flex;
      height: 4.375rem;
      padding: 0.75rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      flex-shrink: 0;
      background: var(--white, #fff);
      position: relative;
      top: -5rem;
      left: 30rem;
      span {
        text-align: center;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 2.8rem */
        background: var(
          --gradient-golden-button,
          linear-gradient(
            98deg,
            #a27b1f -11.22%,
            #d2aa46 10.36%,
            #f4d980 31.94%,
            #e3ba6a 53.52%,
            #f4d067 75.1%,
            #c3a364 96.68%,
            #956e0f 123.65%
          )
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .content {
    height: 3.125rem;
    align-self: stretch;
    color: var(--grey-dark, #424848);
    font-family: "Frank Ruhl Libre";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.575rem */
    margin-top: -4rem;
  }

  .contanct-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5rem;
    align-self: stretch;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    align-self: stretch;

    .values {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch;
      gap: 7.5rem;

      span {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 12.25rem;
        img {
          margin-right: 0.03rem;
          width: 1.5rem;
        }
      }
    }
  }

  .submit-btn {
    button {
      color: var(--on-god, #5a3c1a) !important;
      cursor: pointer;
      display: flex;
      padding: 0.8125rem 3.81rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      align-self: stretch;
      border-radius: 1rem;
      background: var(
        --gradient-gold-slanted,
        linear-gradient(
          126deg,
          #a27b1f 1.44%,
          #d2aa46 17.18%,
          #fae68c 32.92%,
          #e3ba6a 48.66%,
          #fdda75 64.4%,
          #c3a364 80.14%,
          #956e0f 99.82%
        )
      );

      /* button-default */
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
      color: var(--on-gold, #5a3c1a);
      text-align: center;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 1.75rem */
      border: none;
    }
  }

  .gradient-border4 {
    margin: 9.69rem 17.44rem 0rem 17.44rem;
    border-radius: 16px;
    padding: 2px;
    background: linear-gradient(
      90deg,
      #a27b1f 0%,
      #d2aa46 16%,
      #fae68c 32%,
      #e3ba6a 48%,
      #fdda75 64%,
      #c3a364 80%,
      #956e0f 100%
    );
  }

  .feedback {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: -5rem;

    .feedback-item {
      width: 40%;
    }

    .desc {
      display: flex;
      flex-direction: column;
      gap: 6rem;
      margin-top: 2rem;
    }

    .form {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2.5rem;
    }

    .pass-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      label {
        align-self: stretch;
        color: var(--on-god, #5a3c1a);
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 1.75rem */
      }

      input {
        display: flex;
        padding: 1rem 0.75rem 1rem 1rem;
        align-items: center;
        gap: 0.625rem;
        align-self: stretch;

        border-radius: 1.25rem;
        border: 1px solid var(--devider1, #e9d2a8);
        background: var(--white, #fff);
      }
      textarea {
        display: flex;
        padding: 1rem 0.75rem 1rem 1rem;
        align-items: flex-start;
        gap: 0.625rem;
        align-self: stretch;
        background-color: #fff;
        height: 10rem;
        border-radius: 1.25rem;
        border: 1px solid var(--devider1, #e9d2a8);

        color: var(--black, #252a2a);
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 1.575rem */
      }

      input:focus,
      textarea:focus {
        border: 2px solid var(--devider1, #e9d2a8) !important;
      }
    }
  }
}

@media (max-width: 1600px) {
  .main-header {
    img {
      width: 38%;
    }
  }

  .contact-us-container .gradient-border4 {
    margin: 9.69rem 2rem 0rem 2rem;
  }

  .contact-us-container {
    .title {
      top: -5.5rem !important;
      left: 35rem !important;
    }
  }
}
@media (max-width: 1300px) {
  .main-header {
    img {
      width: 37%;
    }
  }

  .contact-us-container {
    .title {
      top: -5.5rem !important;
      left: 26rem !important;
    }
  }
}
@media (max-width: 500px) {
  .contact-us-container {
    .gradient-border4 {
      margin: 4rem 2rem 0rem 2rem;
    }
    .contact-container {
      * {
        font-size: 0.8rem;
      }
    }
    h1 {
      font-size: 2rem;
    }
    .item {
      .values {
        display: grid;
        grid-template-columns: auto auto;
        gap: 1rem;
        span {
          width: 10rem;
        }
      }
    }
    .contanct-items {
      position: relative;
      top: -2rem;
      gap: 3.5rem;
    }
    .content {
      height: auto;
      position: relative;
      top: -2rem;
    }
    .main-header {
      img {
        width: 30%;
      }
    }
    .title {
      top: -5.5rem !important;
      left: 5rem !important;
      span {
        font-size: 1.5rem !important;
      }
    }
    .feed-title {
      left: 4rem !important;
    }
    .item .values {
      span {
        width: 8rem;
      }
      span img {
        width: 1.2rem;
      }
    }
    .social {
      img {
        width: 4rem;
      }
    }
    .feedback {
      flex-direction: column;
      gap: 2.5rem;

      .feedback-item {
        width: 100%;
        margin-top: 0;
      }

      .pass-item label,
      .pass-item textarea,
      button {
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 400px) {
  .contact-us-container {
    .contact-container {
      padding: 2rem 1rem 1rem 1rem;
    }
    .main-header {
      img {
        width: 28%;
      }
    }
    .title {
      left: 1.5rem !important;
      top: -4.5rem !important;
      span {
        font-size: 1.3rem !important;
      }
    }
    .feed-title {
      top: -5.5rem !important;
      left: 3rem !important;
    }
    .item .values {
      span {
        width: 7rem;
      }
    }
  }
}
