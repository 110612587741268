@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  padding: 0;
  margin: 0;
  font-family: "Frank Ruhl Libre", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: rgb(243 244 246);
  background-color: #fff;
  -ms-overflow-style: none;
  width: 100%;
  /* scrollbar-width: none; */
}

/* Chrome */
body::-webkit-scrollbar {
  display: none !important;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

:root {
  --bg: rgba(251, 250, 248, 1);
  --black: rgba(37, 43, 43, 1);
  --button-default: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
  --container: 0px 0px 8px 1px rgba(0, 0, 0, 0.15),
    0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  --devider-1: rgba(233, 210, 168, 1);
  --devider-2: rgba(232, 216, 187, 1);
  --green: rgba(59, 190, 129, 1);
  --grey: rgba(133, 136, 136, 1);
  --grey-dark: rgba(66, 72, 72, 1);
  --m-3white: rgba(255, 255, 255, 1);
  --m3-body-large-font-family: "Roboto", Helvetica;
  --m3-body-large-font-size: 16px;
  --m3-body-large-font-style: normal;
  --m3-body-large-font-weight: 400;
  --m3-body-large-letter-spacing: 0.5px;
  --m3-body-large-line-height: 24px;
  --mode-button-selected: 0px 0px 8px 1px rgba(0, 0, 0, 0.15),
    1px 1px 3px 0px rgba(0, 0, 0, 0.2);
  --on-god: rgba(90, 60, 26, 1);
  --red: rgba(246, 53, 53, 1);
  --white: rgba(255, 255, 255, 1);
}
