.clocks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1.2em 0;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  h5 {
    color: #252b2b;
    margin-top: -2px;
    margin-bottom: 5px;
    margin-left: -10px;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
}

.clock-container,
.trapezoid-parent {
  width: 100%;
}

.analog-clock {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .center-circle {
    position: relative;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    border-radius: 50%;
    z-index: 10;
    border-radius: 0.125rem;
    border: 2px solid #252a2a;
    background: var(
      --gradient-gold-slanted,
      linear-gradient(
        126deg,
        #a27b1f 1.44%,
        #d2aa46 17.18%,
        #fae68c 32.92%,
        #e3ba6a 48.66%,
        #fdda75 64.4%,
        #c3a364 80.14%,
        #956e0f 99.82%
      )
    );
  }
}

.white-circle {
  border: 2px solid #fff !important;
}

.digital-clock {
  color: #858888;
  border-radius: 0.5em;
  margin-left: -10px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.gradient-border7 {
  border-radius: 50%;
  padding: 2px;
  background: linear-gradient(
    -60deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  );
  width: 3.5rem;
  height: 3.5rem;
}

@media only screen and (max-device-height: 854px) {
  .clocks {
    padding: 1em 0;
  }
}
@media only screen and (max-width: 1600px) {
  .clock-container,
  .trapezoid-parent {
    width: 98%;
  }
}
@media only screen and (max-width: 1300px) {
  .clock-container,
  .trapezoid-parent {
    width: 96%;
  }
}
@media only screen and (max-width: 1000px) {
  .clock-container,
  .trapezoid-parent {
    width: 94%;
  }
}
@media only screen and (max-width: 800px) {
  .clock-container,
  .trapezoid-parent {
    width: 100%;
  }
  .clocks {
    // display: grid;
    // grid-template-columns: auto auto auto;
    // gap: 2rem;
  }
}
@media only screen and (max-width: 500px) {
  .clocks {
    flex-wrap: nowrap;
    overflow-x: scroll;
    gap: 4rem;
    padding: 1rem;
  }
  .clocks::-webkit-scrollbar {
    display: none;
  }
  .trapezoid-parent {
    width: 100%;
  }
  .clocks h5 {
    font-size: 0.8rem;
  }
  .digital-clock {
    font-size: 0.8rem;
  }
}
